<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
            <b-sidebar id="add_crmtask" aria-labelledby="sidebar-no-header-title" no-header backdrop width="700px" style="direction:ltr" right title="إضافة ملاحظة" shadow >
                <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span>{{lang.add_task}}</span>
                        </div>
                        <div @click="hide" id="hideTask" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                            <span>{{lang.close}}</span>
                        </div>
                    </div>
                <v-divider></v-divider>
                <v-row style="direction:rtl" class="m-2">
                    <v-col cols="12" md="12" sm="12">
                         <label>{{ lang.agent }}</label>
                        <b-input-group>
                        <b-form-select class="selborder"
                            v-model="task_response"
                            :options="responses"
                        ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <label>{{lang.date}}</label>
                        <b-form-input class="inborder"
                            v-model="task_date"
                            required
                            type="date"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                         <label>{{ lang.status }}</label>
                        <b-input-group>
                        <b-form-select class="selborder"
                            v-model="status"
                            :options="taskStatus"
                        ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                    </v-col>

                    <v-col cols="12" md="12" sm="12">
                        <label>{{lang.task_description}}</label>
                        <b-form-textarea class="inborder"
                            v-model="notes"
                            value=""
                            required
                            ></b-form-textarea>
                    </v-col>

                </v-row>
                </template>
                <template #footer="" class="shadow">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="addNote()" variant="success" class="ma-2" style="width:100px;">{{lang.add}}</b-button>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify'
export default{
    data() {
        return {
            notes: '',
            crm_id: 0,
            task_response: 1,
            users: [],
            status: 1,
            statuss: 1,
            doc_type: "tasks",
            task_date: '',
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        
        responses: function(){
            let t = [];
            for(let i=0;i < this.users.length;i++){
                t.push(
                    {text: this.users[i].full_name, value: this.users[i].id}
                )
            }
            return t;
        },
        taskStatus: function(){
            let t = [];
            for(let i=0;i < this.statuss.length;i++){
                let text = this.lang.langname == 'ar' ? this.statuss[i].name_ar : this.statuss[i].name_en;
                t.push(
                    {text: text, value: this.statuss[i].value}
                )
            }
            return t;
        }
    },
    methods: {
        getUsers(){
            const post = new FormData();
            post.append("type" , "getUsers");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[crm_id]",this.crm_id);
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                console.log(response.data)
                if(response && response.data && response.data.results){
                    this.users = response.data.results.data.results
                }
            })
        },
        getStatics(){
            const post = new FormData();
            post.append("type" , "getStaticOptions");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[phrase]",'taskStatus');
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                console.log(response.data)
                if(response && response.data && response.data.results){
                    this.statuss = response.data.results.data
                }
            })
        },
        addNote(){
            if(this.notes == ''){
                let message = 'يجب ان تقوم باضافة وصف المهمة';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            

            const post = new FormData();
            post.append("type" , "addNewTaskToCRM");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[crm_id]",this.crm_id);
            post.append("data[task_text]",this.notes);
            post.append("data[doc_type]",this.doc_type);
            post.append("data[task_response]",this.task_response);
            post.append("data[status]",this.status);
            post.append("data[task_date]",this.task_date);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            )
            .then((response) => {
                const res = response.data;
                let message = 'تمت الاضافة بنجاح';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.$parent.getCRMData(this.crm_id);
                document.getElementById('hideTask').click();
            });
            
            this.message = '';
        }
    },
    created() {
        this.getUsers();
        this.getStatics();
    },
}
</script>